<template>
	<div class="p-col-12 p-lg-8 p-xl-6 p-fluid contact-form">

		<div class="card login-card">

			<div class="p-grid p-flex-md-row-reverse">
				<div class="p-col-12 p-md-6 bee-bg">
					<div class="p-fluid p-m-2">
						<h3 class="quote">Bienvenue sur FreeCard, votre carte de visite 100% digitale et interactive.</h3>
					</div>
				</div>

				<div class="p-col-12 p-md-6 ">
					<div class="p-fluid p-m-4">

						<h5>Connectez Vous</h5>

						<div class="p-field">
							<InputText type="email" v-model="username" placeholder="email@domaine.fr" required autofocus/>
						</div>
						<div class="p-field">
							<InputText type="password" v-model="pswd" placeholder="********" required/>
						</div>
						<div class="p-field">
							<Button :disabled="loading" type="button" class="b-button"
									@click="tryLogin" label="Se connecter à FreeCard"
									icon="pi pi-wifi"/>
						</div>
					</div>
				</div>
			</div>

			<div v-show="loading" class="p-fluid">
				<InfiniteProgress></InfiniteProgress>
			</div>
		</div>
	</div>
</template>

<script>
import ProfileService from "../service/ProfileService";
import InfiniteProgress from '../components/InfiniteProgress.vue';

export default {
	data() {
		return {
			username: '',
			pswd: '',
			loading: false,
		}
	},
	created() {
		this.profileService = new ProfileService();
	},
	mounted(){

	},
	methods: {
		tryLogin(){
			this.loading = true;
			const vm = this;
			this.profileService.getJWT(this.username, this.pswd)
					.then(res => {
						if(res.error === true){
							//todo do something with the error message
							return;
						}
						this.$router.push({ name: 'dashboard' })
					})
					.catch(err => {
						console.warn('err',err);
					})
					.finally(() => {
						vm.loading = false;
					})
		}
	},
	components: {
		InfiniteProgress
	}
}
</script>

<style scoped>

</style>
